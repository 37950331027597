<template>
  <div class="page">
    <div class="row">
      <div class="col-col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <b-card>
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form-group
              label="Profile Image"
              label-for="account-profile-pic"
            >
              <b-avatar
                size="6rem"
                variant="light-primary"
                :src="user.profile"
                class="accoutProfilePic"
              />

              <b-form-file
                ref="accountProfilePicRef"
                v-model="profileImage"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept="image/*"
                @change="onSelectProfilcePic"
              />
              <!-- v-model="account.profilePic" -->
            </b-form-group>
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="name"
                    v-model="user.name"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="name"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="MLS ID"
              label-for="mls-id"
            >
              <validation-provider
                #default="{ errors }"
                name="MLS ID"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="mls-id"
                    v-model="user.mlsId"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    readonly
                    name="mls-id"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Slug"
              label-for="slug"
            >
              <validation-provider
                #default="{ errors }"
                name="Slug"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="slug"
                    v-model="user.slug"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="slug"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Role"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="role"
                    v-model="user.role"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="role"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="email"
                    v-model="user.email"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="email"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="phone"
                    v-model="user.phone"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="phone"
                    @input="formatPhoneNumber(user.phone)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Website"
              label-for="website"
            >
              <validation-provider
                #default="{ errors }"
                name="Website"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="website"
                    v-model="user.website"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="website"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-alert
              v-if="errorMsg"
              show
              variant="danger"
            >
              <div class="alert-body">
                {{ errorMsg }}
              </div>
            </b-alert>
            <b-button
              variant="outline-primary"
              class="mb-2"
              :disabled="invalid"
              @click="save"
            >
              Save
            </b-button>
          </validation-observer>
        </b-card>
      </div>
      <div class="col-col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <b-card>
          <b-form-group
            label-for="description"
            label="Description"
          >
            <b-form-textarea
              id="description"
              v-model="user.remark"
              rows="15"
            />
          </b-form-group>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BFormGroup, BButton, BInputGroup, BFormInput, BAlert, BFormTextarea, BAvatar, BFormFile,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BButton, ValidationProvider, ValidationObserver, BFormGroup, BInputGroup, BFormInput, BAlert, BFormTextarea, BAvatar, BFormFile,
  },
  data() {
    return {
      errorMsg: '',
      required,
      roleOptions: [
        { value: 'agent', text: 'Agent' },
        { value: 'manager', text: 'Mananger' },
        { value: 'assistant', text: 'Assistant' },
      ],
      profileImage: [],
      user: {
        id: '',
        name: '',
        slug: '',
        phone: '',
        email: '',
        role: '',
        website: '',
        mlsId: '',
        remark: '',
        profile: '',
      },
    }
  },
  created() {
    const id = Number(this.$route.params.id)
    this.$store.dispatch('offices/getAgent', id).then(response => {
      this.user = {
        id: response.data.id,
        name: response.data.name,
        slug: response.data.slug,
        phone: response.data.phone,
        email: response.data.email,
        role: response.data.role,
        website: response.data.website,
        mlsId: response.data.agent_mls_loginName,
        remark: response.data.remark === 'null' ? '' : response.data.remark,
        profile: response.data.profile_image,
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    removeProfilePic() {
      this.profileImage = []
      this.user.profile = ''
    },
    formatPhoneNumber(data) {
      const cleaned = ('' + data).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        this.user.phone = match[1] + '.' + match[2] + '.' + match[3]
      }
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
    save() {
      const formData = new FormData()

      const data = {
        id: this.user.id,
        name: this.user.name,
        slug: this.user.slug,
        phone: this.user.phone,
        email: this.user.email,
        role: this.user.role,
        website: this.user.website,
        agent_mls_loginName: this.user.mlsId,
        remark: this.user.remark,
        profile: this.user.profile,
      }

      Object.keys(data).forEach(key => {
        if (key !== 'profile') {
          formData.append(key, data[key])
        }
      })

      formData.append('profileImage', this.profileImage)
      this.$store.dispatch('offices/updateAgent', formData).then(response => {
        if (response.code === 200) {
          this.showToast('Agent Updated', 'CheckCircleIcon', 'Agent has been updated successfully', 'success', 'bottom-right')
        } else {
          // eslint-disable-next-line prefer-destructuring
          this.errorMsg = response.message[Object.keys(response.message)[0]][0]
        }
      }).catch(err => {
        console.error(err)
      })
    },
    onSelectProfilcePic(e) {
      const image = e.target.files[0]

      if (image.size < 1024 * 100) {
        const reader = new FileReader()

        reader.readAsDataURL(image)

        reader.onload = event => {
          this.user.profile = event.target.result
          this.profileImage = image
        }
      } else {
        this.$refs.accountProfilePicRef.reset()
        this.showToast('Upload Failed', 'AlertCircleIcon', 'File exceeds the maximum file size (500KB)', 'danger', 'bottom-right')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.accoutProfilePic {
    margin: 30px auto;
    display: flex;
}

.b-avatar-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: unset !important;
  min-height: unset !important;
}
</style>

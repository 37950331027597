var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-card',[_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form-group',{attrs:{"label":"Profile Image","label-for":"account-profile-pic"}},[_c('b-avatar',{staticClass:"accoutProfilePic",attrs:{"size":"6rem","variant":"light-primary","src":_vm.user.profile}}),_c('b-form-file',{ref:"accountProfilePicRef",attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":"image/*"},on:{"change":_vm.onSelectProfilcePic},model:{value:(_vm.profileImage),callback:function ($$v) {_vm.profileImage=$$v},expression:"profileImage"}})],1),_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"MLS ID","label-for":"mls-id"}},[_c('validation-provider',{attrs:{"name":"MLS ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"mls-id","state":errors.length > 0 ? false:null,"readonly":"","name":"mls-id"},model:{value:(_vm.user.mlsId),callback:function ($$v) {_vm.$set(_vm.user, "mlsId", $$v)},expression:"user.mlsId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Slug","label-for":"slug"}},[_c('validation-provider',{attrs:{"name":"Slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"slug","state":errors.length > 0 ? false:null,"name":"slug"},model:{value:(_vm.user.slug),callback:function ($$v) {_vm.$set(_vm.user, "slug", $$v)},expression:"user.slug"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"role","state":errors.length > 0 ? false:null,"name":"role"},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"phone"},on:{"input":function($event){return _vm.formatPhoneNumber(_vm.user.phone)}},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Website","label-for":"website"}},[_c('validation-provider',{attrs:{"name":"Website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"website","state":errors.length > 0 ? false:null,"name":"website"},model:{value:(_vm.user.website),callback:function ($$v) {_vm.$set(_vm.user, "website", $$v)},expression:"user.website"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.errorMsg)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])]):_vm._e(),_c('b-button',{staticClass:"mb-2",attrs:{"variant":"outline-primary","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Save ")])]}}])})],1)],1),_c('div',{staticClass:"col-col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-card',[_c('b-form-group',{attrs:{"label-for":"description","label":"Description"}},[_c('b-form-textarea',{attrs:{"id":"description","rows":"15"},model:{value:(_vm.user.remark),callback:function ($$v) {_vm.$set(_vm.user, "remark", $$v)},expression:"user.remark"}})],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }